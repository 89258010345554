import 'jquery-validation'
import 'jquery-validation/dist/additional-methods'
import { getState } from "../helpers/getState";
import { updateCart } from "../helpers/updateCart";
import { getProduct } from "../helpers/getProduct-v3";
import { getRout } from "../helpers/getRout";
import {setContacts} from "../helpers/setContacts";
import {subscribeForm} from "../helpers/subscribeForm";
import '../helpers/slick.min.js';

updateCart()
getRout()
subscribeForm()



getState((data) => {

  const $loader = $('.popup-loading-wrapper');

  const products = data.data.products;

  setContacts(data.data.templates)

  getProduct(products, 'ha-product__content');

  getProduct(products, 'home-product__content');

  getProduct(products, 'banner-flex');

  getProduct(products, 'home-flex', data.data);

  let count = 6;
  $("#loadMore").on("click", function () {
    $(".home-product__box.hidden-product").slice(0, count).removeClass("hidden-product");

    if ($(".home-product__box.hidden-product").length === 0) {
        $("#loadMore").fadeOut();
    }
  });


  $('.home-flex').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $loader.hide();
});
